import React from "react"
import Nav from '../components/nav';
import MobileNav from '../components/mobileNav';
import Hero from '../components/hero';
import DesignList from '../components/designList';
import CodeList from '../components/codeList';
import Layout from "../components/layout"
import Footer from '../components/footer';
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <MobileNav />
    <Nav />
    <Hero />
    <DesignList />
    <CodeList />
    <Footer />
  </Layout>
)

export default IndexPage