import React from 'react';
import Link from 'gatsby-link';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';

import './hero.scss';

library.add(fab)

const Hero = () => (
  <div>
    <div className="hero">
      <h1 className="hero__header-title-one">
        Design.
      </h1>
      <h1 className="hero__header-title-two">
        Code.
      </h1>
    </div>
    <div className="hero__header-link-bar">
      <div className="hero__header-link-bar-border" />
      <a href="https://www.instagram.com/_robertlester/?hl=en" target="_blank" rel="noopener noreferrer" className="hero__header-link-bar-link">
        <FontAwesomeIcon icon={['fab', 'instagram']} size="lg" />
      </a>
      <a href="https://twitter.com/robertlester__?lang=en" target="_blank" rel="noopener noreferrer" className="hero__header-link-bar-link">
        <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" />
      </a>
      <a href="https://www.linkedin.com/in/robert-lester-a0258784/" target="_blank" rel="noopener noreferrer" className="hero__header-link-bar-link">
        <FontAwesomeIcon icon={['fab', 'linkedin-in']} size="lg" />
      </a>
      <a href="https://github.com/roblester09" target="_blank" rel="noopener noreferrer" className="hero__header-link-bar-link">
        <FontAwesomeIcon icon={['fab', 'github']} size="lg" />
      </a>
      <a href="https://dribbble.com/RobSLester" target="_blank" rel="noopener noreferrer" className="hero__header-link-bar-link">
        <FontAwesomeIcon icon={['fab', 'dribbble']} size="lg" />
      </a>
    </div>
    <div className="hero__about-me-container">
      <p className="hero__about-me-description">
        Hello! I'm Robert Lester an experienced &nbsp;
        <strong style = {{ color: '#fff' }}>Digital Designer and Developer</strong>&nbsp;
        with &nbsp;<strong style = {{ color: '#fff' }}>several years of work experience.</strong>
      </p>
      <p className="hero__about-me-description">
        Find out more <Link to="/" className="hero__about-me-link">about me.</Link>
      </p>
    </div>
  </div>
);

export default Hero;