import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Card from '../card'

export default () => (
   <StaticQuery
     query={graphql` 
      query CodeListQuery {
        allContentfulCode(
          limit: 3
          sort: { fields: [createdAt], order: DESC }
          filter: {
          node_locale: {eq: "en-US",}
          home: {eq: true}
        }
        ) {
        edges {
          node {
            id
            slug
            title
            codeCategory {
                name
                id
            }
            featuredImage {
              fluid(maxWidth: 1200, quality: 85) {
                src
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `}
    render={data => (
      <Card
        sectionTitle="Code"
        sectionData={data.allContentfulCode}
        sectionCategory="codeCategory"
        sectionLink="/code"
      />
    )}
   />
 )
